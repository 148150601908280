import type {
  NextPage,
  GetStaticPropsResult,
  GetStaticPropsContext,
  GetStaticPathsResult,
} from 'next';
import { ParsedUrlQuery } from 'querystring';
import Head from 'next/head';
import React, { useEffect } from 'react';
import PageModal from '@/components/General/PageModal';
import Stream from '@/components/Stream/Stream';
import {
  getPostsByLatestActivity,
  getLatestPosts,
  getPostsByGiveSupport,
} from '@/firebase/streamSSR';
import { useSignupBanner } from '@/providers/SignupBannerProvider';
import { SortTypes } from '@/components/Stream/StreamSortDropdown';

interface IProps {
  posts?: string | null;
}

interface IPageParams extends ParsedUrlQuery {
  filter: [string];
}

const Home: NextPage = (props: IProps) => {
  const { posts } = props;
  const { resetMessage } = useSignupBanner();

  useEffect(() => {
    resetMessage();
  }, [resetMessage]);

  let initialData = [];
  if (posts && typeof posts === 'string') {
    initialData = JSON.parse(posts);
  }

  return (
    <div id="stream">
      <Head>
        <title>All the latest on Shift.ms</title>
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/home`}
        />
        <meta
          name="description"
          content="Learn about multiple sclerosis and connect with other MSers"
        />
        <meta property="og:type" content="website" />
      </Head>
      <Stream initialData={initialData} />
      <PageModal parent="/home" />
    </div>
  );
};

export default Home;

export async function getStaticProps(
  context: GetStaticPropsContext,
): Promise<GetStaticPropsResult<IProps>> {
  const { filter } = context.params as IPageParams;
  let results;

  if (filter.length && filter[0] === SortTypes.NewestPosts) {
    results = await getLatestPosts();
  } else if (filter.length && filter[0] === SortTypes.GiveSupport) {
    results = await getPostsByGiveSupport();
  } else {
    results = await getPostsByLatestActivity();
  }

  if (results && results.length) {
    const posts = JSON.stringify(results);
    return {
      props: {
        posts,
      },
      revalidate: 1800,
    };
  }
  return {
    props: {
      posts: null,
    },
  };
}

export async function getStaticPaths(): Promise<GetStaticPathsResult> {
  return {
    paths: [
      { params: { filter: [SortTypes.RecentActivity] } },
      { params: { filter: [SortTypes.NewestPosts] } },
      { params: { filter: [SortTypes.GiveSupport] } },
    ],
    fallback: false,
  };
}
