import { mdiNewBox } from '@mdi/js';
import Icon from '@mdi/react';
import useSWRInfinite from 'swr/infinite';
import useIntersectionObserver from '@react-hook/intersection-observer';
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  endBefore,
  where,
} from 'firebase/firestore';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import React, { useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import StreamSortDropdown, { SortTypes } from './StreamSortDropdown';
import { db } from '@/firebase/firebase';
import { useAuth } from '@/providers/AuthProvider';
import { useAds } from '@/providers/AdProvider';
import { useNav } from '@/providers/NavProvider';
import PostCard from './PostCard';
import PostCreateForm from './PostCreateForm';
import PostCreateFormPlaceholder from './PostCreateFormPlaceholder';
import Space from './Space';
import StreamNoPosts from './StreamNoPosts';
import { IPost } from '@/types/Collections/posts';
import Banner from '../../public/assets/images/shiftms_app_banner_desktop.png';
import MobileBanner from '../../public/assets/images/shiftms_app_banner_mobile.png';

const POSTS_PER_PAGE = 10;

const StreamSidebar = dynamic(() => import('./StreamSidebar'), {
  ssr: false,
});

interface IProps {
  initialData: IPost[];
}

const Stream = (props: IProps): JSX.Element => {
  const { initialData } = props;

  const router = useRouter();
  const { isLoggedIn } = useAuth();

  const [isBrowser] = useState<boolean>(typeof window !== 'undefined');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [firstPost, setFirstPost] = useState<IPost | null>(null);
  const [lastPost, setLastPost] = useState<IPost | null>(null);
  const [isEndOfPosts, setIsEndOfPosts] = useState<boolean>(false);
  const [newPosts, setNewPosts] = useState<IPost[]>([]);
  const [isFormActive, setFormActive] = useState<boolean>(false);
  const [sortField, setSortField] = useState<
    'lastActive' | 'created' | 'giveSupport' | 'following'
  >('lastActive');
  const { setPostModalOpen } = useNav();
  const { spaces } = useAds();

  const [pageBottomRef, setPageBottomRef] = useState<any>();
  const { isIntersecting } = useIntersectionObserver(pageBottomRef);

  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 767;

  useEffect(() => {
    if (router.query.filter) {
      if (router.query.filter[0] !== sortField) {
        if (router.query.filter[0] === SortTypes.NewestPosts) {
          setSortField('created');
        } else if (router.query.filter[0] === SortTypes.RecentActivity) {
          setSortField('lastActive');
        } else if (router.query.filter[0] === SortTypes.GiveSupport) {
          setSortField('giveSupport');
        }
      }
    }
  }, [router.query.filter, sortField]);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsPageLoading(true);
      setNewPosts([]);
      setLastPost(null);
      setFirstPost(null);
      setIsEndOfPosts(false);
    };
    router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router]);

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      setIsPageLoading(false);
    };
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);

  const getQueryConstraints = useCallback(
    (isFirstPage: boolean, isSubscription = false) => {
      const constraints: any[] = [
        where('isDeleted', '==', false),
        where('isPublished', '==', true),
      ];
      if (sortField === 'giveSupport') {
        constraints.push(where('hasNegativeSentiment', '==', true));
        constraints.push(
          orderBy('reactions.support', 'asc'),
          orderBy('created', 'desc'),
        );
      } else {
        constraints.push(orderBy(sortField, 'desc'));
      }

      if (lastPost && !isFirstPage) {
        if (sortField === 'giveSupport') {
          constraints.push(
            startAfter(lastPost.reactions.support, lastPost.lastActive),
          );
        } else if (sortField === 'following') {
          // Do nothing
        } else {
          constraints.push(startAfter(lastPost[sortField]));
        }
      }

      if (isSubscription && firstPost) {
        if (sortField === 'giveSupport') {
          constraints.push(
            endBefore(firstPost.reactions.support, firstPost.lastActive),
          );
        } else if (sortField === 'following') {
          // Do nothing
        } else {
          constraints.push(endBefore(firstPost[sortField]));
        }
      }

      constraints.push(limit(POSTS_PER_PAGE));
      return constraints;
    },
    [sortField, lastPost, firstPost],
  );

  const fetchPosts = async (key: string) => {
    setIsFetching(true);
    const params = new URLSearchParams(key.slice(key.indexOf('?') + 1));
    const pageNum = params.get('page') || 0;

    const isFirstPage = Number(pageNum) === 0;

    const postCollectionRef = collection(db, 'posts');
    const constraints = getQueryConstraints(isFirstPage, false);

    return getDocs(query(postCollectionRef, ...constraints)).then(
      querySnapshot => {
        const posts: IPost[] = [];
        querySnapshot.forEach(doc => {
          const data = doc.data() as IPost;
          posts.push({
            ...data,
            id: doc.id,
          });
        });
        setIsFetching(false);
        if (posts.length < POSTS_PER_PAGE) {
          setIsEndOfPosts(true);
        }
        return posts;
      },
    );
  };

  const swrKey = (pageIndex: number) => {
    return `/home/${sortField}?page=${pageIndex}`;
  };

  const {
    data: feedData,
    setSize: setFeedSize,
    mutate: mutateFeed,
  } = useSWRInfinite(swrKey, fetchPosts, {
    revalidateFirstPage: true,
    refreshInterval: 0,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (feedData && feedData[0][0]) {
      setFirstPost(feedData[0][0]);
      setLastPost(
        feedData[feedData.length - 1][feedData[feedData.length - 1].length - 1],
      );
    }
  }, [feedData]);

  useEffect(() => {
    if (isLoggedIn) {
      const constraints = getQueryConstraints(true, true);
      const unsub = onSnapshot(
        query(collection(db, 'posts'), ...constraints),
        querySnapshot => {
          const posts = [] as IPost[];
          querySnapshot.forEach(doc => {
            posts.push({
              ...doc.data(),
              id: doc.id,
            } as IPost);
          });
          setNewPosts(posts);
        },
      );
      return () => {
        unsub();
        setNewPosts([]);
        return;
      };
    }
  }, [isLoggedIn, getQueryConstraints]);

  useEffect(() => {
    if (isIntersecting && !isPageLoading && !isFetching && !isEndOfPosts) {
      setFeedSize(feedSize => feedSize + 1);
    }
  }, [isIntersecting, setFeedSize, isPageLoading, isFetching, isEndOfPosts]);

  const refresh = () => {
    mutateFeed();
    setNewPosts([]);
  };

  const postListItems = (posts: IPost[]) => {
    let n = 0;
    return posts?.map((post, i) => {
      let output = [];
      if (i % (POSTS_PER_PAGE / 2) === 0) {
        output = [
          <React.Fragment key={`post-with-widget-${post.id}`}>
            <PostCard key={post.id} post={post} />
            <Space key="widget" n={n} />
          </React.Fragment>,
        ];
        if (spaces.length - 1 > n) {
          n += 1;
        } else {
          n = 0;
        }
      } else {
        output = [<PostCard key={post.id} post={post} />];
      }
      return output;
    });
  };

  const postList = () => {
    if (!feedData && !initialData) {
      return <StreamNoPosts />;
    }

    if (feedData) {
      const posts = feedData ? ([] as IPost[]).concat(...feedData) : [];
      return postListItems(posts);
    }

    if (initialData) {
      return postListItems(initialData);
    }
    return null;
  };

  return (
    <>
      <div className="stream container page-container has-sidebar">
        <div className="container is-narrow">
          <div>
            {isFormActive ? (
              <PostCreateForm onClose={() => setFormActive(false)} />
            ) : (
              <PostCreateFormPlaceholder
                onClick={() =>
                  isMobile ? setPostModalOpen(true) : setFormActive(true)
                }
              />
            )}
            <div>
              <a href="https://shift.ms/app" className="block">
                <Image
                  src={isMobile ? MobileBanner : Banner}
                  alt="For a better experience get the Shift.ms app"
                  width={650}
                />
              </a>
            </div>
            <StreamSortDropdown sortField={sortField} />
            {!isPageLoading && !isFetching && newPosts?.length ? (
              <div className="buttons">
                <button
                  onClick={refresh}
                  className="button is-medium is-fullwidth is-dark"
                  type="button">
                  <span className="icon">
                    <Icon path={mdiNewBox} size={1.25} />
                  </span>
                  <span>Refresh Stream</span>
                </button>
              </div>
            ) : null}
            <div className="posts">{postList()}</div>

            {feedData && !isBrowser && !isEndOfPosts && (
              <button
                type="button"
                onClick={() => setFeedSize(feedSize => feedSize + 1)}
                className={`button is-fullwidth is-large${
                  isPageLoading || isFetching ? ' is-loading' : ''
                }`}>
                Load more
              </button>
            )}

            <div
              className={`obs${isFetching ? ' loading-block' : ''}`}
              ref={setPageBottomRef}
            />
          </div>
        </div>
      </div>
      <StreamSidebar />
    </>
  );
};

export default Stream;
