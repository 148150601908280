import { mdiMenuDown } from '@mdi/js';
import Icon from '@mdi/react';
import Link from 'next/link';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useAuth } from '@/providers/AuthProvider';

export enum SortTypes {
  RecentActivity = 'active',
  NewestPosts = 'newest',
  GiveSupport = 'support',
  Following = 'following',
}

interface IProps {
  children?: React.ReactNode;
  sortField: 'lastActive' | 'created' | 'giveSupport' | 'following';
  searchTerm?: string;
}

const StreamSortDropdown = ({
  sortField,
  children,
  searchTerm,
}: IProps): JSX.Element => {
  const { isLoggedIn } = useAuth();
  const flagEnabled = useFeatureFlagEnabled('2023_05_stream_sort_by_support');

  const label = () => {
    switch (sortField) {
      case 'lastActive':
      default:
        return 'Recent activity';
      case 'created':
        return 'Newest posts';
      case 'giveSupport':
        return 'Give support';
      case 'following':
        return 'Following';
    }
  };

  return (
    <div className="is-flex is-align-items-center mb-4 mt-4 feed-filter">
      {!children && <span className="mr-3 is-font-brand-bold">Sort by</span>}
      {children && <span className="mr-3">{children}</span>}
      <div className="dropdown is-hoverable">
        <div className="dropdown-trigger">
          <button
            type="button"
            className="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu">
            <span className="icon-text">
              <span>{label()}</span>
              <span className="icon">
                <Icon path={mdiMenuDown} size={1.25} />
              </span>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <Link
              href={searchTerm ? `${searchTerm}/` : '/home'}
              className="dropdown-item">
              Recent activity
            </Link>
            <Link
              href={
                searchTerm
                  ? `${searchTerm}/${SortTypes.NewestPosts}`
                  : SortTypes.NewestPosts
              }
              className="dropdown-item">
              Newest posts
            </Link>
            {flagEnabled && (
              <Link
                href={
                  searchTerm
                    ? `${searchTerm}/${SortTypes.GiveSupport}`
                    : SortTypes.GiveSupport
                }
                className="dropdown-item">
                Give support
              </Link>
            )}
            {!searchTerm && isLoggedIn && (
              <Link href={SortTypes.Following} className="dropdown-item">
                Following
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default StreamSortDropdown;
