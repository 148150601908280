import { mdiClock, mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useAuth } from '@/providers/AuthProvider';
import { useSignupBanner } from '@/providers/SignupBannerProvider';
import Avatar from '../Profile/Avatar';

const PostCreateFormPlaceholder = ({
  onClick,
}: {
  onClick: () => void;
}): JSX.Element => {
  const { authLoaded, isLoggedIn, user } = useAuth();
  const { setMessage, open } = useSignupBanner();

  const handleShowSignupBanner = () => {
    setMessage('Sign in to write a post');
    open();
  };

  if (authLoaded && isLoggedIn) {
    return (
      <a
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyDown={onClick}
        className="post-create-form-placeholder">
        <div className="media">
          <figure className="media-left is-hidden-mobile">
            <div className="image is-64x64">
              <Avatar
                imageUrl={user?.profileImgUrl}
                username={user?.username}
                size={64}
              />
            </div>
          </figure>
          <div className="media-content">
            <span className="icon is-large">
              <Icon path={mdiPlusBox} size={1.5} />
            </span>
            <span className="post-create-form-placeholder-text">
              Write a post...
            </span>
          </div>
        </div>
      </a>
    );
  }

  if (authLoaded) {
    return (
      <a
        role="button"
        tabIndex={0}
        onClick={handleShowSignupBanner}
        onKeyDown={handleShowSignupBanner}
        className="post-create-form-placeholder">
        <div className="media">
          <figure className="media-left is-hidden-mobile">
            <div className="image is-64x64">
              <Avatar imageUrl={null} username={null} />
            </div>
          </figure>
          <div className="media-content">
            <span className="icon is-large">
              <Icon path={mdiPlusBox} size={1.5} />
            </span>
            <span className="post-create-form-placeholder-text">
              Write a post...
            </span>
          </div>
        </div>
      </a>
    );
  }

  return (
    <div className="post-create-form-placeholder is-loading">
      <div className="media">
        <figure className="media-left is-hidden-mobile">
          <div className="image is-64x64">
            <Avatar imageUrl={null} username={null} />
          </div>
        </figure>
        <div className="media-content">
          <span className="icon is-large">
            <Icon path={mdiClock} size={1.5} />
          </span>
          <span className="post-create-form-placeholder-text">
            Please wait...
          </span>
        </div>
      </div>
    </div>
  );
};

PostCreateFormPlaceholder.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default PostCreateFormPlaceholder;
