const StreamNoPosts = (): JSX.Element => (
  <div className="card has-background-dark mt-4">
    <div className="card-content has-text-white">
      <h2 className="title is-size-2 has-text-white has-text-centered">
        Sorry, there are no posts yet.
      </h2>
    </div>
  </div>
);

export default StreamNoPosts;
