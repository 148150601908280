import {
  mdiOpenInNew, mdiPlay, mdiPost, mdiRhombusSplit,
} from '@mdi/js';
import Icon from '@mdi/react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import * as gtag from '../../../lib/gtag';
import { useAds } from '../../../providers/AdProvider';
import { useAuth } from '../../../providers/AuthProvider';
import { useNav } from '../../../providers/NavProvider';
import SpaceImage from './image';
import { db, functions } from '../../../firebase/firebase';
import PostCard from '../PostCard';

const Space = (props) => {
  const { n = false, sidebar = false } = props;
  const { spaces, audience, sidebarSpace } = useAds();
  const [post, setPost] = useState();
  const { setPostModalOpen } = useNav();
  const { uid, auth } = useAuth();
  useEffect(() => {
    if (n && spaces[n]) {
      gtag.event({
        action: 'space.show',
        params: {
          id: spaces[n].id,
          title: spaces[n].title,
          audience,
        },
      });
    }
  }, [spaces, audience, n]);

  useEffect(() => {
    if (spaces[n] && spaces[n].post_embed) {
      const splitPath = spaces[n]?.url?.split('/');
      if (splitPath) {
        const postTitleID = splitPath[splitPath?.length ? splitPath?.length : 0 - 1];
        const postTitleIDPath = postTitleID && postTitleID.split('-');
        const postID = postTitleIDPath && postTitleIDPath[postTitleIDPath.length - 1];
        if (postID) {
          getDoc(doc(db, 'posts', postID))
            .then((d) => {
              if (d.exists()) {
                setPost({ id: d.id, ...d.data() });
              }
            })
            .catch(() => {
              // console.log('[Error: Fetch Space Post]', err);
            });
        }
      }
    }
  }, [n, spaces]);

  const eventAdd = async () => {
    if (uid && spaces[n].id) {
      const eventAddFunction = httpsCallable(
        functions,
        'eventCallable-eventAdd',
      );
      eventAddFunction({
        uid,
        eventType: 'PROMO_SPACE:CLICKED',
        eventSource: 'PROMO_SPACE',
        eventSourceId: spaces[n].id,
        meta: { audience, title: spaces[n].title },
        email: auth?.email,
        platform: 'web'
      });
    }
  };

  const clickEvent = () => {
    if (n && spaces[n]) {
      gtag.event({
        action: 'space.click',
        params: {
          id: spaces[n].id,
          title: spaces[n].title,
          audience,
        },
      });
      if (spaces[n].trigger_post_create_form) {
        setPostModalOpen(true);
      }
      eventAdd();
    }
  };

  const type = (space) => {
    switch (space.type) {
      case 'blog':
      case 'post':
        return mdiPost;

      case 'film':
      case 'series':
      case 'doc':
        return mdiPlay;

      case 'collection':
        return mdiRhombusSplit;

      case 'link':
      default:
        return mdiOpenInNew;
    }
  };

  if ((spaces && spaces[n]) || (sidebarSpace && sidebar)) {
    let space = false;
    if (sidebarSpace && sidebar) {
      space = sidebarSpace;
    } else {
      space = spaces[n];
    }
    if (space) {
      if (
        space.embedded_url
        && (space.embedded_url.substring(0, 24) === 'https://www.videoask.com'
          || space.embedded_url.substring(0, 20) === 'https://videoask.com')
      ) {
        return (
          <div className="widget">
            <div
              dangerouslySetInnerHTML={{
                __html: `<iframe src='${space.embedded_url}' allow='camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;' width='100%' height='600px' style='border: none; border-radius: 24px' />`,
              }}
            />
          </div>
        );
      }

      return post && post.isPublished && !post.isDeleted ? (
        <PostCard key={post.id} post={post} isSpace />
      ) : (
        <div className="widget">
          <a
            href={space.trigger_post_create_form ? null : space.url}
            title={space.title}
            className="widget-link"
            rel="noreferrer"
            onClick={clickEvent}
          >
            <div
              className="card"
              style={{
                backgroundColor: space.background_colour,
                color: space.text_colour,
              }}
            >
              <div className="card-image">
                {space.thumbnail ? (
                  <figure className="image">
                    <Image
                      src={`/assets/images/ads/${space.thumbnail}`}
                      alt={space.title}
                      width={670}
                      height={370}
                    />
                  </figure>
                ) : (
                  <SpaceImage
                    url={space.embedded_url ? space.embedded_url : space.url}
                  />
                )}
              </div>
              <div className="card-description">
                <h3>{space.excerpt ? space.excerpt : space.title}</h3>
              </div>
              <div className="card-content">
                <div className="media is-font-brand-bold">
                  <div className="media-left">
                    <span className="icon">
                      <Icon path={type(space)} size={1} />
                    </span>
                  </div>
                  <div className="media-content">
                    {space.type !== 'link' ? (
                      <span className="card-type">{space.type_label}</span>
                    ) : null}
                    <span className="card-title">
                      <h3>{space.excerpt ? space.excerpt : space.title}</h3>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      );
    }
  }
  return null;
};

Space.propTypes = {
  n: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sidebar: PropTypes.bool,
};

Space.defaultProps = {
  n: false,
  sidebar: false,
};

export default Space;
